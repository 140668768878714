<template src="./views/ZoneCard.html"></template>
<script>
  import Donant from '@/models/donant';
  import Donation from '@/models/donation';
  import Setting from '@/models/setting';
  import axios from 'axios';
  import Transaction from '@/models/transaction';
  import moment from 'moment';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/css/index.css';
  import { useVuelidate } from '@vuelidate/core';
  import { required, numeric, minLength, maxLength, helpers } from '@vuelidate/validators';

  export default{
    name: 'ZoneCard',
    setup () {
      return { v$: useVuelidate() }
    },
    components: { Loading },
    props: { donant: Donant, transaction: Transaction },
    mounted(){
      this.setyears();
    },
    data: () => ({
      setting: new Setting,
      acceptance: false,
      years: [],
      overlay: true,
      overlay_msg: 'Por favor espera...',
      output: {
        error: null
      },
      donation: new Donation(),
      state: true
    }),
    validations () {
      return{
        transaction: {
          card_holder: { 
            required: helpers.withMessage('Requerido.', required),
            minLength: helpers.withMessage(() => 'Mínimo 5 caracteres.',  minLength(5)),
          },
          card_number: { 
            required: helpers.withMessage('Requerido.', required),
            numeric:  helpers.withMessage('Solo números.', numeric),
            minLength: helpers.withMessage(() => 'Mínimo 16 caracteres.',  minLength(16)), 
            maxLength: helpers.withMessage(() => 'Máximo 16 caracteres.', maxLength(16))
          },
          card_cvc: { required: helpers.withMessage('Requerido.', required) },
          card_exp_month: { required: helpers.withMessage('Requerido.', required) },
          card_exp_year: { required: helpers.withMessage('Requerido.', required) },
          card_installments: { required: helpers.withMessage('Requerido.', required) }
        }
      }
    },
    methods: {

      setyears(){
        let now = moment().format('YYYY');
        for (let i = 0; i < 10; i++) {
          let year = parseInt(now) + i;
          let l = year.toString();
          let v = year.toString().substring(l.length - 2, l.length);
          let item = {
            label: l,
            value: v
          };
          this.years.push(item);
        }
        this.overlay = false;
      },

      async begintransaction(){
        if(!await this.v$.$validate()){
          return;
        }
        this.overlay_msg = 'Iniciando transacción...';
        this.overlay = true;
        const encrypt = {
          "number": this.transaction.card_number,
          "cvc": this.transaction.card_cvc,
          "exp_month": String(this.transaction.card_exp_month),
          "exp_year": String(this.transaction.card_exp_year),
          "card_holder": this.transaction.card_holder
        };

        //Donante
        try {
          this.overlay_msg = 'Registrando donante...';
          await axios.post(this.setting.params.api + '/donacion/transacciones/add/donate', this.donant)
          .then(r => {
            if(r.data.status){
              this.donation.donante = r.data.data;
              this.donation.formulario = this.transaction.form;
              this.donation.valor = this.transaction.amount;
              this.donation.centavos = this.transaction.amount_in_cents;
            }else{
              this.overlay = false;
              this.output.error = "Error al procesar la donación. Código 501.";
              this.state = false;
            }
          });
        } catch (error) {
          this.overlay = false;
          this.error = error.message;
          return;
        }

        if(!this.state){return;}

        //Donación
        try {
          this.overlay_msg = 'Creando donación...';
          await axios.post(this.setting.params.api + '/donacion/transacciones', this.donation)
          .then(r => {
            if(r.data.status){
              this.donation.reference = r.data.reference;
              this.donation.integrity = r.data.integrity;
              this.donation.tipo = 'DIGITAL';
            }else{
              this.overlay = false;
              this.output.error = "Error al procesar la donación. Código 502.";
              this.state = false;
            }
          });
        } catch (error) {
          this.overlay = false;
          this.error = error.message;
          return;
        }

        if(!this.state){return;}

        //Tokenización
        try {
          this.overlay_msg = 'Encriptando datos...';
          await axios.post(this.setting.params.wompi + '/tokens/cards', encrypt, {
            headers: { accept: "application/json", Authorization: "Bearer " + this.setting.params.key }
          })
          .then(r => {
            if(r.data.status === 'CREATED'){
              this.transaction.setcard(r.data.data);
            }else{
              this.overlay = false;
              this.output.error = "Error al procesar la donación. Código 503.";
              this.state = false;
            }
          });          
        } catch (error) {
          this.overlay = false;
          this.error = error.message;
          return;
        }
        
        if(!this.state){return;}

        //Validar transaccion
        this.overlay_msg = 'Procesando transacción...';
        let it = null; //idtransaction

        if(this.transaction.recurrent){
          //Transacción recurrente

          const frm = new FormData;
          frm.append("donant", JSON.stringify(this.donant));
          frm.append("donation", JSON.stringify(this.donation));
          frm.append("transaction", JSON.stringify(this.transaction));
          await axios.post(this.setting.params.api + "/donacion/transacciones/recurrente", frm)
          .then(r => {
            if(r.data){
              it = r.data.transaction.id;
            }else{
              this.state = false;
            }
          });
        }else{
          //Transaccion Normal

          const datatransaction = {
            "public-key": this.setting.params.key,
            "acceptance_token": this.transaction.acceptance_token,
            "amount_in_cents": this.transaction.amount_in_cents,
            "signature": this.donation.integrity,
            "reference": this.donation.reference,
            "currency": "COP",
            "redirect_url": this.setting.params.redir,
            "payment_method_type": this.transaction.payment_method_type,
            "payment_method": {
              "type": "CARD",
              "extra": {
                "name": this.transaction.card_name,
                "brand": this.transaction.card_brand,
                "last_four": this.transaction.card_last_four,
                "processor_response_code": "51"
              },
              "token": this.transaction.card_token,
              "installments": this.transaction.card_installments
            },
            "customer_email": this.donant.correo_personal,
            "customer_data": {
              "phone_number": this.donant.telefono,
              "full_name": this.donant.nombres + ' ' + this.donant.apellidos,
              "email": this.donant.correo_personal,
              "legal-id": this.donant.documento,
              "legal-id-type": this.donant.tipo_documento
            }
          }

          //procesar transacción con wompi
          try {
            await axios.post(this.setting.params.wompi + '/transactions', datatransaction,{
              headers: { accept: "application/json", Authorization: "Bearer " + this.setting.params.key }
            }).then(r => {
              if(r.data){
                it = r.data.data.id;
              }else{
                this.state = false;
              }
            });
          } catch (error) {
            this.overlay = false;
            this.error = error.message;
            return;
          }
        }   
        
        //Validar cierre

        if(!this.state){
          this.overlay = false;
          this.error = 'Error al intentar realizar la transacción. Código 504.';
          return;
        }

        if(it){
          await this.delay(5000);
          this.confirm(it);
        }else{
          this.overlay = false;
          this.error = 'Error al obtener respuesta de transacción. Código 509.';
        }
      },

      async confirm(t){
        await axios.get(this.setting.params.wompi + '/transactions/' + t.toString())
        .then(r => {
          this.overlay = false;
          this.$router.push({ name: 'landingtransaction', params: { id: r.data.data.id } });
        });
      },

      delay(timeout){
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(true);
          }, timeout);
        });
      }

    }
  }
</script>
<template src="./LandingVisible.html"></template>
<script>
  import axios from 'axios'; 
  import Setting from '@/models/setting.js';
  import swal from 'sweetalert';
  import JsonExcel from "vue-json-excel3";
  import { useVuelidate } from '@vuelidate/core';
  import { required } from '@vuelidate/validators';
  export default{
    name: 'LandingVisible',
    components:{
      downloadExcel:JsonExcel
    },
    setup () {
      return { v$: useVuelidate() }
    },
    mounted(){
      this.load();
    },
    data: () => ({
      setting: new Setting(),
      search: null,
      isloading: false,
      isprocess: false,
      dialogadd: false,
      expanded: [],
      headers: [
        {title: "A", align: "start", value: "id" }, 
        {title: "Nombre", align: "start", value: "nombre" }, 
        {title: "Donaciones", align: "start", value: "donaciones" }, 
        {title: "Recaudo", align: "start", value: "recaudo"}, 
        {title: "Creado", align: "end", value: "created_at"}
      ],
      data: [],
      dataexport: [],
      landing: {
        name: null,
        description: null,
        message: null
      }
    }),
    validations () {
      return {
        landing:{ 
          name: { required },
          description: { required },
          message: { required }
        },
      }
    },
    expose: ['load'],
    methods: {

      async load(){
        try {
          this.isloading = true;
          await axios.get(this.setting.params.api+'/admin/landing/load/open',{
            headers:{ Authorization: localStorage.getItem('token') }
          })
          .then(res => {
            this.isloading = false;
            let result = res.data;
            this.data = result.data;
          });
        } catch (error) {
          this.isloading = false;
          this.setting.showerror(error);
        }
      },

      async add(){
        let validate = await this.v$.$validate();
        if(!validate){
          return;
        }
        try {
          this.isprocess = true;
          this.dialogadd = false;
          await axios.post(this.setting.params.api+'/admin/landing/add',this.landing,{
            headers:{ Authorization: localStorage.getItem('token') }
          })
          .then(res => {
            this.isprocess = false;
            let result = res.data;
            if(result.status){
              swal({title: "success",text: result.message, icon: "success",
                buttons: {text: "ACEPTAR",}
              });
              this.load();
            }else{
              swal({title: "warning",text: result.message, icon: "warning",
                buttons: {text: "ACEPTAR",}
              });
            }
          });
        } catch (error) {
          this.isprocess = false;
          this.setting.showerror(error);
        }
      },

      async hide(landing){
        try {
          this.isprocess = true;
          await axios.put(this.setting.params.api + '/admin/landing/change',{id: landing, state: 'hide'},{
            headers:{ Authorization: localStorage.getItem('token') }
          })
          .then(res => {
            this.isprocess = false;
            let result = res.data;
            if(result.status){
              swal({title: "success",text: result.message, icon: "success",
                buttons: {text: "ACEPTAR",}
              });
              this.load();
              this.$emit('visibletohide');
            }else{
              swal({title: "warning",text: result.message, icon: "warning",
                buttons: {text: "ACEPTAR",}
              });
            }
          });
        } catch (error) {
          this.isprocess = false;
          this.setting.showerror(error);
        }
      },

      async remove(value){
        try {
          let confirm = null;
          await swal({title: "¿eliminar registro?",text: '¿Seguro quiere eliminar este registro?',icon: "error",dangerMode: true,
          buttons: {
            cancel: "CANCELAR",
            confirm: "ACEPTAR"
          }
          }).then(function(conf){
            confirm = conf;
          });

          if(!confirm){
            return;
          }

          this.isprocess = true;
          await axios.delete(this.setting.params.api+'/admin/landing/delete/'+value,{
            headers:{ Authorization: localStorage.getItem('token') }
          })
          .then(res => {
            this.isprocess = false;
            let result = res.data;
            if(result.status){
              swal({title: "success",text: result.message,icon: "success",
                buttons: {text: "ACEPTAR"}
              });
              this.load();
            }else{
              this.setting.showerror({message: result.message});
            }
          });
        } catch (error) {
          this.isprocess = false;
          this.setting.showerror(error);
        }
      },

      async close(value){
        try {
          let confirm = null;
          await swal({title: "cerrar campaña?",text: '¿Seguro quiere cerrar está campaña?',icon: "error",dangerMode: true,
          buttons: {
            cancel: "CANCELAR",
            confirm: "ACEPTAR"
          }
          }).then(function(conf){
            confirm = conf;
          });

          if(!confirm){
            return;
          }

          this.isprocess = true;
          await axios.put(this.setting.params.api+'/admin/landing/close',{id: value},{
            headers:{ Authorization: localStorage.getItem('token') }
          })
          .then(res => {
            this.isprocess = false;
            let result = res.data;
            if(result.status){
              swal({title: "success",text: result.message,icon: "success",
                buttons: {text: "ACEPTAR"}
              });
              this.load();
            }else{
              this.setting.showerror({message: result.message});
            }
          });
        } catch (error) {
          this.isprocess = false;
          this.setting.showerror(error);
        }
      },

      async download(landing){
        try {
          this.isprocess = true;
          await axios.get(this.setting.params.api+'/admin/donaciones/get/'+landing,{
            headers:{ Authorization: localStorage.getItem('token') }
          })
          .then(res => {
            this.isprocess = false;
            let result = res.data;
            if(result.status){
              this.dataexport = result.donaciones;
            }
          });
        } catch (error) {
          this.isprocess = false;
          this.setting.showerror(error);
        }
      }
      
    }
  }
</script>
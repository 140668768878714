<template src="./FormsList.html"></template>
<script>
  import axios from 'axios'; 
  import Setting from '@/models/setting.js';
  import Form from '@/models/form';
  import swal from 'sweetalert';
  import { useVuelidate } from '@vuelidate/core';
  import { required, numeric } from '@vuelidate/validators';
  export default{
    name: 'FormsList',
    setup () {
      return { v$: useVuelidate() }
    },
    mounted(){
      this.landing = this.$route.params.referencia;
      if(!this.landing){
        this.$router.push({ name: 'managerlanding' });
      }
      this.load();
    },
    data: () => ({
      setting: new Setting(),
      form : new Form(),
      landing: null,
      isloading: false,
      isprocess: false,
      snackbar: {
        state: false,
        text: ''
      },
      dialogadd: false,
      dialogembed: false,
      dialogopen: false,
      headers: [
        {title: "A", align: "start", value: "id" }, 
        {title: "IdFormulario", align: "start", value: "id" }, 
        {title: "Código", align: "start", value: "codigo" }, 
        {title: "Target", align: "start", value: "objetivo"}, 
        {title: "Donaciones", align: "center", value: "donaciones" }, 
        {title: "Recaudo", align: "end", value: "recaudo"}, 
        {title: "Estado", align: "end", value: "estado"}, 
        {title: "Creado", align: "end", value: "created_at"}
      ],
      headerdonations: [
        {title: "Referencia", align: "start", value: "referencia" }, 
        {title: "Documento", align: "end", value: "documento" }, 
        {title: "Nombres", align: "start", value: "nombres" }, 
        {title: "Apellidos", align: "start", value: "apellidos"}, 
        {title: "Correo Electrónico", align: "center", value: "correo_personal" }, 
        {title: "Teléfono", align: "end", value: "telefono"}, 
        {title: "Entidad", align: "center", value: "entidad"}, 
        {title: "Tipo", align: "start", value: "tipo"},
        {title: "Donación", align: "start", value: "valor"},
        {title: "Fecha", align: "start", value: "fecha"},
        {title: "Estado", align: "start", value: "estado"}
      ],
      data: [],
      donations: [],
      coverfile: [],
      codeembed: null
    }),
    validations () {
      return {
        form:{ 
          title: { required },
          subtitle: { required },
          target: { required },
          begin: { required },
          end: { required },
          btn1: { required, numeric }
        }
      }
    },
    methods: {

      async load(){
        try {
          this.isloading = true;
          await axios.get(this.setting.params.api+'/admin/landing/form/load/'+this.landing,{
            headers:{ Authorization: localStorage.getItem('token') }
          }).then(res => {
            this.isloading = false;
            let result = res.data;
            this.form.idlanding = result.landing.id;
            this.data = result.formularios;
          });
        } catch (error) {
          this.isloading = false;
          this.setting.showerror(error);
        }
      },

      async add(){
        let validate = await this.v$.$validate();
        if(!validate){
          return;
        }
        try {
          this.dialogadd = false;
          this.isprocess = true;
          const frm = new FormData;
          frm.append('setting', JSON.stringify(this.form));
          frm.append('cover', this.coverfile[0]);
          await axios.post(this.setting.params.api + '/admin/landing/form/add', frm , {
            headers:{ Authorization: localStorage.getItem('token') }
          }).then(res => {
            this.isprocess = false;
            let result = res.data;
            if(result.status){
              swal({title: "success",text: result.message,icon: "success",
                buttons: {text: "ACEPTAR"}
              });
              this.load();
            }else{
              this.setting.showerror({message: result.message});
            }
          });
        } catch (error) {
          this.isprocess = false;
          this.setting.showerror(error);
        }
      },

      copyto(id,codigo){
        let host = window.location.host;
        let link = `https://${host}/landing/search/${id}/${codigo}`;
        navigator.clipboard.writeText(link);
        this.snackbar.text = 'Se ha copiado el enlace de la campaña en el portapapeles. Puedes pegar este enlace en una nueva pestaña o compartirlo';
        this.snackbar.state = true;
      },

      embedto(id,codigo){
        let host = window.location.host;
        let link = `https://${host}/landing/search/${id}/${codigo}`;
        this.codeembed = `<iframe src="${link}" width="500" height="600"></iframe>`;
        this.dialogembed = true;
      },

      async open(id){
        try {
          this.isprocess = true;
          await axios.get(this.setting.params.api + '/admin/donaciones/show/'+id, {
            headers:{ Authorization: localStorage.getItem('token') }
          }).then(res => {
            this.isprocess = false;
            let result = res.data;
            if(result.status){
              this.dialogopen = true;
              this.donations = result.donaciones;
            }else{
              this.setting.showerror({message: result.message});
            }
          });          
        } catch (error) {
          this.isprocess = false;
          this.setting.showerror(error);
        }
      },

      async close(form){
        try {
          let confirm = null;
          await swal({title: "cerrar formulario?",text: '¿Seguro quiere cerrar este formulario?',icon: "error",dangerMode: true,
          buttons: {
            cancel: "CANCELAR",
            confirm: "ACEPTAR"
          }
          }).then(function(conf){
            confirm = conf;
          });

          if(!confirm){
            return;
          }
          this.isprocess = true;
          const frm = new FormData;
          frm.append('form',form);
          await axios.put(this.setting.params.api+'/admin/landing/form/close', frm,{
            headers:{ Authorization: localStorage.getItem('token') }
          }).then(res => {
            this.isprocess = false;
            let result = res.data;
            if(result.status){
              swal({title: "success",text: result.message,icon: "success",
                buttons: {text: "ACEPTAR"}
              });
            }else{
              this.setting.showerror({message: result.message});
            }
          });
        } catch (error) {
          this.isprocess = false;
          this.setting.showerror(error);
        }
      },

      async remove(form){
        try {
          let confirm = null;
          await swal({title: "¿eliminar registro?",text: '¿Seguro quiere eliminar este registro?',icon: "error",dangerMode: true,
          buttons: {
            cancel: "CANCELAR",
            confirm: "ACEPTAR"
          }
          }).then(function(conf){
            confirm = conf;
          });

          if(!confirm){
            return;
          }
          this.isprocess = true;
          const frm = new FormData;
          frm.append('form',form);
          await axios.delete(this.setting.params.api+'/admin/landing/form/delete/'+form,{
            headers:{ Authorization: localStorage.getItem('token') }
          }).then(res => {
            this.isprocess = false;
            let result = res.data;
            if(result.status){
              swal({title: "success",text: result.message,icon: "success",
                buttons: {text: "ACEPTAR"}
              });
              this.load();
            }else{
              this.setting.showerror({message: result.message});
            }
          });
        } catch (error) {
          this.isprocess = false;
          this.setting.showerror(error);
        }
      }

    }
  }
</script>
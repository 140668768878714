<div class="border h-100">
  <v-toolbar density="compact">
    <v-toolbar-title>Campañas visibles</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn icon @click="dialogadd = true">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-toolbar>
  <v-data-table :headers="headers" :items="data" :loading="isloading" :search="search" density="compact">
    <template v-slot:item="{ item }">
      <tr>
        <td>
          <div class="d-flex">
            <v-btn icon :elevation="0" size="small" @click="$router.push({name: 'managerforms', params: {referencia: item.referencia}})">
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-btn>
            <v-btn icon :elevation="0" size="small" @click="close(item.id)">
              <v-icon>mdi-calendar-remove</v-icon>
            </v-btn>
            <v-btn icon :elevation="0" size="small" @click="hide(item.id)">
              <v-icon>mdi-eye-off-outline</v-icon>
            </v-btn>
            <download-excel v-if="item.donaciones"
            :data="dataexport"
            type="xlsx"
            worksheet="donations"
            name="registro_donaciones.xlsx">
              <v-btn icon :elevation="0" size="small" @click="download(item.id)">
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </download-excel>
            <v-btn icon :elevation="0" size="small" v-if="!item.donaciones" @click="remove(item.id)">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </div>
        </td>
        <td>{{ item.nombre }}</td>
        <td>{{ item.donaciones }}</td>
        <td>{{ setting.moneyformat(item.recaudo) }}</td>
        <td class="text-right">{{ setting.formatdate(item.created_at) }}</td>
      </tr>
    </template>
  </v-data-table>

  <!-- #region -->
  <v-dialog v-model="dialogadd" max-width="800" persistent>
    <v-card title="Nueva campaña">
      <v-card-text>
        <div class="text-caption">Por favor complete los sigientes datos.</div>
        <div class="mt-3">
          <v-text-field v-model="landing.name" label="Nombre de la campaña" :error-messages="v$.landing.name.$errors.map(e => e.$message)" />
          <v-textarea v-model="landing.description" label="Descripción" :error-messages="v$.landing.description.$errors.map(e => e.$message)" />
          <v-textarea v-model="landing.message" label="Mensaje de despedida" :error-messages="v$.landing.message.$errors.map(e => e.$message)" />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text="Cancelar" @click="dialogadd = false"></v-btn>
        <v-btn color="primary" variant="tonal" text="Agregar" :loading="isloading" @click="add"></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- #endregion -->

  <!-- #progress -->
  <v-dialog v-model="isprocess" max-width="320" persistent>
    <v-list class="py-2" color="primary" elevation="12" rounded="lg">
      <v-list-item prepend-icon="mdi-update" title="Por favor espera...">
        <template v-slot:prepend>
          <div class="pe-4">
            <v-icon color="primary" size="x-large"></v-icon>
          </div>
        </template>
        <template v-slot:append>
          <v-progress-circular color="primary" indeterminate="disable-shrink" size="16" width="2"></v-progress-circular>
        </template>
      </v-list-item>
    </v-list>    
  </v-dialog>
  <!-- #endregion -->
</div>